import React from 'react';

const Services = () => {
    const listItems = ['Union Pacific', 'Purple Wave Auction', 'University of Nebraska-Lincoln'];
    const divStyle = {
        marginLeft: '5%',
        marginRight: '5%'
    
    }
    const listItemStyle = {
        fontWeight: 'bold',
        
    };
  return (
    <div style={divStyle}> 
      <p style={listItemStyle}>We bring a wealth of experience gained at the following corporations and institutions to BIBLIOCORTEX, including:</p>
      <ul>
        {listItems.map((item, index) => (
          <li key={index} style={listItemStyle}>{item}</li>
        ))}
      </ul>
      <footer >
      © 2025, BIBLIOCORTEX LLC. All rights reserved.
    </footer>
    </div>
  );
};

export default Services;
