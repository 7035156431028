import React from 'react';

const PastProjects = () => {
  const divStyle = {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        marginLeft: '5%',
        marginRight: '5%'
    
    }
  const linkStyle = {
    fontWeight: 'bold',
    textDecoration: 'none',
    color: 'black',
    marginBottom: '100px',
    
  };
  
  const externalLinks = [
    {
     title: 'Sloan Survey Exploratory Data Analysis for K-Means Clustering',
     url: process.env.PUBLIC_URL + '/Mar_12_EDA.html'
    },
    
    {
     title: 'Sloan Survey Redshift Decision Tree Classifier',
     url: process.env.PUBLIC_URL + '/Mar_11_ML.html'
    },
    
    {
     title: 'Sloan Survey Decision Tree 2',
     url: process.env.PUBLIC_URL + '/Mar_8_ML.html'
    },
    
    {
     title: 'Sloan Survey Decision Tree 1',
     url: process.env.PUBLIC_URL + '/Mar_7_ML.html'
    },
    
    {
     title: 'Linear Regression Example',
     url: process.env.PUBLIC_URL + '/jupiter_test_1.html'
    },
    
    {
     title: 'Sine Wave',
     url: process.env.PUBLIC_URL + '/interactive_sinewave.html'
    },
    
    {
     title: 'The Map',
     url: process.env.PUBLIC_URL + '/map_test.html'
    },
    
    {
     title: 'Platonic Solids',
     url: process.env.PUBLIC_URL + '/platonic_solids.html'
    },
    
    {
      title: 'Interactive Physics',
      url: 'https://github.com/WLNORGREN/TALOS/tree/master/Physics_Simulations'
    },
    
    {
      title: 'Hexadecimal Calculator',
      url: 'https://github.com/WLNORGREN/TALOS/tree/master/Calculators'
    },
    
    {
      title: 'Machine Learning and Astronomy',
      url: 'https://github.com/WLNORGREN/TALOS/tree/master/Astronomy_Projects'
    }


  ];

  return (
    <div style={divStyle}>
      <h3>Past Projects</h3>
      <ul >
        {externalLinks.map((link, index) => (
          <li   key={index}>
            <a  style={linkStyle} href={link.url} target="_blank" rel="noopener noreferrer">
              {link.title}
            </a>
          </li>
        ))}
      </ul>
      <footer>
      © 2025, BIBLIOCORTEX LLC. All rights reserved.
    </footer>
    </div>
  );
};

export default PastProjects;

