import React from 'react';
import Header from './Header';

const About = () => {

  const divStyle = {
        marginLeft: '5%',
        marginRight: '5%'
    
    }
  return (
    <div style={divStyle}>
    
     <p>BIBLIOCORTEX provides consulting services in a wide variety of areas of software development including data analysis, 3-D modeling, and web development.</p>
    
        <ul>
          <li>
            <h3><strong>DATA ANALYSIS</strong></h3>
            <p>The BIBLIOCORTEX team has experience working with Big Data in multiple industries, including railroads and equipment auctioning.

We carry out analysis in multiple programming languages, including Python, R, and SQL.

In addition, we deploy interactive data visualization apps with platforms such as Shiny.</p>
          </li>
          <li>
            <h3><strong>3-D DESIGN</strong></h3>
            <p>We design, test, and implement 3-D design solutions with cutting-edge engines, such as Unreal Engine 5.

These solutions include both static animations and interactive simulations of virtual environments.</p>
          </li>
          <li>
            <h3><strong>WEB DEVELOPMENT</strong></h3>
            <p>We develop and implement modern websites using the Node/React Javascript stack.

In addition, we also design responsive solutions for mobile applications using Android Studio.</p>
          </li>
        </ul>

    <footer>
      © 2025, BIBLIOCORTEX LLC. All rights reserved.
    </footer>
    </div>
  );
};

export default About;
